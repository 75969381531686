import * as React from "react";
import { FC } from "react";
import { RouterLink, RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "src/components/NavigationSidebar/NavigationSidebar";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import style from "../../styles/page.module.css";
import iconGroup from "src/assets/icon/groups.svg";
import iconPerson from "src/assets/icon/person.svg";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import iconDoc from "src/assets/icon/menu-doc.svg";
import iconPayment from "src/assets/icon/payment.svg";
import iconShop from "src/assets/icon/shop.svg";
import iconShopTwo from "src/assets/icon/shop_two.svg";
import iconList from "src/assets/icon/list.svg";
import { Logo } from "src/components/UI/Logo/Logo";
import { AccountSelect, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";
import { UserProfileStore } from "src/stores/user/UserProfileStore";
import { UserRouteNames, UserViewMap } from "src/pages/user/UserRoutes";
import { useIntl } from "react-intl";
import { LocalizedText, useLocalizedText } from "src/locales/LocalizedText";
import { AccountingRouteNames, AccountingViewMap } from "src/pages/accounting/AccountingRoutes";

const UserNavigation: FC<{
    title: string;
    description: string;
    onLogout: () => void;
    userStore: UserProfileStore;
    accountSelectStore: AccountSelectStore;
}> = ({ title, description, onLogout, userStore, accountSelectStore }) => {
    const intl = useIntl();
    return useObserver(() => (
        <NavigationSidebar>
            <AccountSelect store={accountSelectStore}>
                <Logo color={"dark"} className={"mt-4"} title={title} description={description} />
            </AccountSelect>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comUserNavigation__LocalizedNavigationDelimiter__fiz" }, intl)}
            />
            <RouterLink activeClassName={navigationStyle.navigationActive} routeName={UserRouteNames.organizations}>
                <img src={iconGroup} alt="" className="mr-3" />
                <LocalizedText id={"comUserNavigation__RouterLink__organizations"} />
            </RouterLink>
            <RouterLink activeClassName={navigationStyle.navigationActive} routeName={UserRouteNames.documents}>
                <img src={iconDoc} alt="" className="mr-3" />
                <LocalizedText id={"comUserNavigation__RouterLink__documents"} />
            </RouterLink>
            {!!userStore.profile?.canViewSupportChat && (
                <RouterLink activeClassName={navigationStyle.navigationActive} routeName={UserRouteNames.support}>
                    <img src={iconPerson} alt="" className="mr-3" />
                    <LocalizedText id={"comUserNavigation__RouterLink__support"} />
                </RouterLink>
            )}
            {!!userStore.profile?.isAccountingEnabled && (
                <React.Fragment>
                    <LocalizedNavigationDelimiter
                        content={useLocalizedText({ id: "comUserNavigation__LocalizedNavigationDelimiter__buh" }, intl)}
                    />
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.contractList}
                        params={{ oid: "user" }}
                    >
                        <img src={iconList} alt="" className="mr-3" />
                        <LocalizedText id={"comUserNavigation__RouterLink__userAccountingContractList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.accountList}
                        params={{ oid: "user" }}
                    >
                        <img src={iconPayment} alt="" className="mr-3" />
                        <LocalizedText id={"comUserNavigation__RouterLink__userAccountingAccountList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.serviceProvisionList}
                        params={{ oid: "user" }}
                    >
                        <img src={iconShopTwo} alt="" className="mr-3" />
                        <LocalizedText id={"comUserNavigation__RouterLink__userAccountingServiceProvisionList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.serviceList}
                        params={{ oid: "user" }}
                    >
                        <img src={iconShop} alt="" className="mr-3" />
                        <LocalizedText id={"comUserNavigation__RouterLink__userAccountingServiceList"} />
                    </RouterLink>
                </React.Fragment>
            )}
            {!!userStore.connectableServices.length && (
                <LocalizedNavigationDelimiter
                    content={useLocalizedText(
                        { id: "comUserNavigation__LocalizedNavigationDelimiter__services" },
                        intl
                    )}
                />
            )}
            {userStore.connectableServices.map((service) => (
                <RouterLink
                    key={service.id}
                    activeClassName={navigationStyle.navigationActive}
                    routeName={UserRouteNames.userApp}
                    params={{ appId: service.id.toString() }}
                >
                    <img src={iconDoc} alt="" className="mr-3" />
                    {service.name}
                </RouterLink>
            ))}
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comUserNavigation__LocalizedNavigationDelimiter__cab" }, intl)}
            />
            <a href="#" onClick={onLogout}>
                <img src={iconLogOut} alt="" className="mr-3" />
                <LocalizedText id={"comUserNavigation__RouterLink__logout"} />
            </a>
        </NavigationSidebar>
    ));
};

export const UserShell: FC = () => {
    const {
        rootStore: { routerStore, userLoginStore, userProfileStore },
    } = useRootStore();
    const intl = useIntl();
    return useObserver(() => (
        <div className={style.fullHeight}>
            <div className={style.fullHeight + " container ml-0 " + style.fullWidth}>
                <div className={style.flexList + " row " + style.fullHeight}>
                    <div className={"col-lg-2 " + style.darkBackground}>
                        <div className={"sticky-top mt-4"}>
                            <UserNavigation
                                userStore={userProfileStore}
                                accountSelectStore={userProfileStore}
                                title={
                                    userProfileStore.profile?.name ??
                                    useLocalizedText({ id: "comUserShell__title" }, intl)
                                }
                                description={useLocalizedText({ id: "comUserShell__description" }, intl)}
                                onLogout={() => userLoginStore.logout()}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-10 p-0 m-0"}>
                        <SuspensePlaceholder>
                            <RouterView routerStore={routerStore} viewMap={{ ...UserViewMap, ...AccountingViewMap }} />
                        </SuspensePlaceholder>
                    </div>
                </div>
            </div>
        </div>
    ));
};
