export interface ConnectableServiceInfoDto {
        id : string;
        name : string;
        embedUrl : string;
        isAvailableToAll : boolean;
        isConnected : boolean;
}

export interface ErrorCode {
        code : string;
        description : string;
}

export interface ResultT<T> {
        value : T;
        success : boolean;
        error : ErrorCode;
}

export interface ListResult<T> {
        items : T[];
        totalCount : number;
}

export interface DocumentDto {
        id : number;
        title : string;
        extension : string;
        createdAt : string;
        url : string;
}

export enum MessageBalloonTypeDto {
        Message = "Message",
        Document = "Document",
}

export interface MessageBalloonDto {
        id : number;
        text : string;
        type : MessageBalloonTypeDto;
        createdAt : string;
        userId : number;
        organizationId : string | null;
        extension : string;
        url : string;
        isIncoming : boolean;
}

export enum OrganizationType {
        Organization = "Organization",
        Individual = "Individual",
}

export interface OrganizationInfo {
        type : OrganizationType;
        inn : number;
        ogrn : number;
        kpp : string;
        title : string;
        legalAddress : string;
        physicalAddress : string;
        physicalAddressMatchesLegalAddress : boolean;
        postalAddress : string;
        postalAddressMatchesLegalAddress : boolean;
        phone : string;
        telegram : string;
        email : string;
        website : string;
        ceoPosition : string;
        ceoFullName : string;
        ceoFullNameGenitive : string;
        readyForDiadocDocumentExchange : boolean;
        comment : string;
}

export interface OrganizationDto {
        id : string;
        title : string;
        createdAt : string;
        userId : number;
        economicEntityId : string | null;
        info : OrganizationInfo;
        isAccountingEnabled : boolean;
        canViewSupportChat : boolean;
}

export interface OrganizationInfoDto {
        type : OrganizationType;
        inn : number;
        ogrn : number;
        kpp : string;
        title : string;
        legalAddress : string;
        physicalAddress : string;
        physicalAddressMatchesLegalAddress : boolean;
        postalAddress : string;
        postalAddressMatchesLegalAddress : boolean;
        phone : string;
        telegram : string;
        email : string;
        website : string;
        ceoPosition : string;
        ceoFullName : string;
        ceoFullNameGenitive : string;
        readyForDiadocDocumentExchange : boolean;
        comment : string;
}

export interface UserProfileDto {
        id : number;
        externalId : string;
        email : string;
        name : string;
        isAccountingEnabled : boolean;
        canViewSupportChat : boolean;
}

export interface UPanelAccountDto {
        id : string;
        currencyType : string;
        name : string;
}

export interface UPanelAccountCreateArgsDto {
        name : string;
        currencyType : string;
}

export interface RemoteUiDefinitionDto<TResponse> {
        definition : any;
        value : TResponse;
}

export interface UPanelEconomicEntityContractCreateArgsDto {
        name : string;
        issuerSubAccountId : string;
        partnerId : string;
        partnerSubAccountId : string;
}

export interface UPanelEconomicEntityContractDto {
        id : string;
        name : string;
        issuerId : string;
        issuerSubAccountId : string;
        partnerId : string;
        partnerSubAccountId : string;
}

export interface Result {
        success : boolean;
        error : ErrorCode;
}

export interface UPanelEconomicEntityTariffDto {
        id : string;
        name : string;
        economicEntityId : string;
}

export interface UPanelEconomicEntityCurrencyTypeCreateArgsDto {
        name : string;
}

export interface UPanelEconomicEntityCurrencyTypeDto {
        id : string;
        name : string;
        economicEntityId : string;
}

export interface UPanelEconomicEntityDto {
        id : string;
        name : string;
        createdAt : string;
}

export interface UPanelEconomicEntityCreateArgsDto {
        name : string;
}

export interface EventDto {
        id : string;
        parentId : string | null;
        scope : string;
        createdAt : string;
        isRequired : boolean;
        payload : string;
}

export interface SimpleEventGraphDto {
        event : EventDto;
        edges : SimpleEventGraphDto[];
}

export enum UPanelTransactionType {
        PayIn = "PayIn",
        PayOut = "PayOut",
        Transfer = "Transfer",
}

export interface UPanelInternalTransactionEntryDto {
        id : string;
        name : string;
        subAccountDebitId : string;
        subAccountCreditId : string;
        parentId : string | null;
        createdAt : string;
        value : string;
        type : UPanelTransactionType;
}

export interface UPanelProjectProfileDto {
        id : string;
        organizationId : string;
        rootEconomicEntityId : string;
        projectName : string;
        projectDescription : string;
        organizationTitle : string;
        hasUpanel : boolean;
}

export interface UPanelEconomicEntityServiceProvisionCreateArgsDto {
        serviceId : string;
        contractId : string;
        name : string;
}

export interface UPanelEconomicEntityServiceProvisionDto {
        id : string;
        contractId : string;
        serviceId : string;
        name : string;
}

export interface UPanelEconomicEntityServiceCreateArgsDto {
        name : string;
}

export interface UPanelEconomicEntityServiceDto {
        id : string;
        economicEntityId : string;
        name : string;
}

export interface UPanelSubAccountDto {
        id : string;
        accountId : string;
        name : string;
}

export interface UPanelSubAccountCreateArgsDto {
        name : string;
}

export interface UPanelEconomicEntityTariffCreateArgsDto {
        name : string;
}

export interface UPanelEconomicEntityTariffVersionCreateArgsDto {
        tariffId : string;
        validSince : string | null;
        validUntil : string | null;
}

export interface UPanelEconomicEntityTariffVersionDto {
        id : string;
        tariffId : string;
        createdAt : string;
        validSince : string | null;
        validUntil : string | null;
}

export interface UPanelEconomicEntityTariffVersionUpdateArgsDto {
        validSince : string | null;
        validUntil : string | null;
}

export interface UPanelEconomicEntityRateDto {
        serviceId : string;
        serviceName : string;
        tariffId : string;
        versionId : string;
        currencyId : string;
        currencyName : string;
        amount : string;
}

export enum UPanelProvisionType {
        Transaction = "Transaction",
        Continuously = "Continuously",
}

export interface UPanelEconomicEntityRateProvisionTypeDto {
        type : UPanelProvisionType;
        schedule : string;
        timestamp : string | null;
}

export interface UPanelEconomicEntityRateProvisionDto {
        rate : UPanelEconomicEntityRateDto;
        provision : UPanelEconomicEntityRateProvisionTypeDto;
}

export interface UPanelAttachServiceToTariffArgsDto {
        serviceId : string;
        currencyId : string;
        amount : number;
        provision : UPanelProvisionType;
        schedule : string;
        timestamp : string | null;
}

export interface UPanelProjectCreateArgsDto {
        organizationBookKeeperConnectionId : string;
        name : string;
        description : string;
}

export interface UPanelProjectUpdateArgsDto {
        name : string;
        description : string;
}

export interface UPanelProjectDto {
        id : string;
        name : string;
        description : string;
        createdAt : string;
        updatedAt : string;
        organizationBookKeeperConnectionId : string | null;
}

export interface UPanelBookKeeperConnectionDto {
        id : string;
        name : string;
        apiUrl : string;
        createdAt : string;
        updatedAt : string;
}

export interface UPanelBookKeeperConnectionCreateArgsDto {
        id : string;
        createdAt : string;
        updatedAt : string;
        name : string;
        apiUrl : string;
}

export interface UPanelConnectableServiceDto {
        id : string;
        name : string;
        embedUrl : string;
        internalApiUrl : string;
        internalApiToken : string;
        isVisibleToAll : boolean;
        isAvailableToAll : boolean;
        jwtTokenServiceName : string;
        jwtTokenSharedKey : string;
        isConnected : boolean;
}

export interface UPanelConnectableServiceCreateArgsDto {
        id : string;
        name : string;
        embedUrl : string;
        internalApiUrl : string;
        internalApiToken : string;
        isVisibleToAll : boolean;
        isAvailableToAll : boolean;
        jwtTokenServiceName : string;
        jwtTokenSharedKey : string;
}

export interface UPanelOrganizationBookKeeperConnectionDto {
        id : string;
        organizationId : string;
        bookKeeperConnectionId : string;
        name : string;
        apiKey : string;
        createdAt : string;
        updatedAt : string;
}

export interface UPanelOrganizationBookKeeperConnectionCreateArgsDto {
        id : string;
        createdAt : string;
        updatedAt : string;
        name : string;
        organizationId : string;
        bookKeeperConnectionId : string;
        apiKey : string;
}

export interface UPanelAdminOrganizationDto {
        id : string;
        createdAt : string;
        userId : number;
        economicEntityId : string | null;
        type : OrganizationType;
        inn : number;
        ogrn : number;
        kpp : string;
        title : string;
        legalAddress : string;
        physicalAddress : string;
        physicalAddressMatchesLegalAddress : boolean;
        postalAddress : string;
        postalAddressMatchesLegalAddress : boolean;
        phone : string;
        telegram : string;
        email : string;
        website : string;
        ceoPosition : string;
        ceoFullName : string;
        ceoFullNameGenitive : string;
        readyForDiadocDocumentExchange : boolean;
        comment : string;
}

export interface UPanelAdminOrganizationCreateArgsDto {
        id : string;
        createdAt : string;
        userId : number;
        economicEntityId : string;
        type : OrganizationType;
        inn : number;
        ogrn : number;
        kpp : string;
        title : string;
        legalAddress : string;
        physicalAddress : string;
        physicalAddressMatchesLegalAddress : boolean;
        postalAddress : string;
        postalAddressMatchesLegalAddress : boolean;
        phone : string;
        telegram : string;
        email : string;
        website : string;
        ceoPosition : string;
        ceoFullName : string;
        ceoFullNameGenitive : string;
        readyForDiadocDocumentExchange : boolean;
        comment : string;
}

export interface UPanelAdminProjectDto {
        id : string;
        name : string;
        description : string;
        createdAt : string;
        updatedAt : string;
        organizationId : string;
        organizationBookKeeperConnectionId : string | null;
        uPanelApiConnectionId : string | null;
        rootEconomicEntityId : string | null;
}

export interface UPanelAdminProjectCreateArgsDto {
        id : string;
        createdAt : string;
        updatedAt : string;
        name : string;
        description : string;
        organizationBookKeeperConnectionId : string | null;
        uPanelApiConnectionId : string | null;
        rootEconomicEntityId : string | null;
}

export interface UPanelApiConnectionDto {
        id : string;
        name : string;
        rpcUrl : string;
        apiKey : string;
        createdAt : string;
        updatedAt : string;
}

export interface UPanelApiConnectionCreateArgsDto {
        id : string;
        createdAt : string;
        updatedAt : string;
        name : string;
        rpcUrl : string;
        apiKey : string;
}

export interface UPanelAdminUserDto {
        id : string;
        externalId : string;
        economicEntityId : string | null;
}

export interface UPanelAdminUserCreateArgsDto {
        id : string;
        externalId : string;
        name : string;
        email : string;
        economicEntityId : string | null;
}


export interface ICoreRpcProxyForUserConnectableServiceRpc {
    getServices () : Promise<ConnectableServiceInfoDto[]>;
    getService (serviceId : number) : Promise<ConnectableServiceInfoDto>;
    isConnected (serviceId : number) : Promise<ResultT<boolean>>;
    connect (serviceId : number) : Promise<ResultT<string>>;
    getToken (serviceId : number) : Promise<ResultT<string>>;
}
export class CoreRpcProxyForUserConnectableServiceRpc implements ICoreRpcProxyForUserConnectableServiceRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getServices () : Promise<ConnectableServiceInfoDto[]>    {
        return this.parent.send<ConnectableServiceInfoDto[]>({Target: 'UserConnectableServiceRpc', MethodSignature: 'C0dldFNlcnZpY2Vz', Arguments: [] });
    }
    public getService (serviceId : number) : Promise<ConnectableServiceInfoDto>    {
        return this.parent.send<ConnectableServiceInfoDto>({Target: 'UserConnectableServiceRpc', MethodSignature: 'CkdldFNlcnZpY2UMU3lzdGVtLkludDMy', Arguments: [serviceId] });
    }
    public isConnected (serviceId : number) : Promise<ResultT<boolean>>    {
        return this.parent.send<ResultT<boolean>>({Target: 'UserConnectableServiceRpc', MethodSignature: 'C0lzQ29ubmVjdGVkDFN5c3RlbS5JbnQzMg==', Arguments: [serviceId] });
    }
    public connect (serviceId : number) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserConnectableServiceRpc', MethodSignature: 'B0Nvbm5lY3QMU3lzdGVtLkludDMy', Arguments: [serviceId] });
    }
    public getToken (serviceId : number) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserConnectableServiceRpc', MethodSignature: 'CEdldFRva2VuDFN5c3RlbS5JbnQzMg==', Arguments: [serviceId] });
    }
}
export interface ICoreRpcProxyForUserDocumentRpc {
    getAll (skip : number, take : number, query : string) : Promise<ListResult<DocumentDto>>;
    getById (id : number) : Promise<DocumentDto>;
    delete (id : number) : Promise<void>;
    create (title : string, extension : string, file : number[]) : Promise<number>;
}
export class CoreRpcProxyForUserDocumentRpc implements ICoreRpcProxyForUserDocumentRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (skip : number, take : number, query : string) : Promise<ListResult<DocumentDto>>    {
        return this.parent.send<ListResult<DocumentDto>>({Target: 'UserDocumentRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [skip,take,query] });
    }
    public getById (id : number) : Promise<DocumentDto>    {
        return this.parent.send<DocumentDto>({Target: 'UserDocumentRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public delete (id : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'UserDocumentRpc', MethodSignature: 'BkRlbGV0ZQxTeXN0ZW0uSW50MzI=', Arguments: [id] });
    }
    public create (title : string, extension : string, file : number[]) : Promise<number>    {
        return this.parent.send<number>({Target: 'UserDocumentRpc', MethodSignature: 'BkNyZWF0ZQ1TeXN0ZW0uU3RyaW5nDVN5c3RlbS5TdHJpbmcOU3lzdGVtLkludDMyW10=', Arguments: [title,extension,file] });
    }
}
export interface ICoreRpcProxyForUserLoginRpc {
    startLogin () : Promise<string>;
    completeLogin (url : string, state : string) : Promise<ResultT<string>>;
    startLogout (token : string) : Promise<string>;
    checkAuthToken () : Promise<boolean>;
}
export class CoreRpcProxyForUserLoginRpc implements ICoreRpcProxyForUserLoginRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public startLogin () : Promise<string>    {
        return this.parent.send<string>({Target: 'UserLoginRpc', MethodSignature: 'ClN0YXJ0TG9naW4=', Arguments: [] });
    }
    public completeLogin (url : string, state : string) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserLoginRpc', MethodSignature: 'DUNvbXBsZXRlTG9naW4NU3lzdGVtLlN0cmluZw1TeXN0ZW0uU3RyaW5n', Arguments: [url,state] });
    }
    public startLogout (token : string) : Promise<string>    {
        return this.parent.send<string>({Target: 'UserLoginRpc', MethodSignature: 'C1N0YXJ0TG9nb3V0DVN5c3RlbS5TdHJpbmc=', Arguments: [token] });
    }
    public checkAuthToken () : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'UserLoginRpc', MethodSignature: 'DkNoZWNrQXV0aFRva2Vu', Arguments: [] });
    }
}
export interface ICoreRpcProxyForUserMessageRpc {
    getAll (skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>;
    send (title : string) : Promise<number>;
}
export class CoreRpcProxyForUserMessageRpc implements ICoreRpcProxyForUserMessageRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>    {
        return this.parent.send<ListResult<MessageBalloonDto>>({Target: 'UserMessageRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [skip,take,query] });
    }
    public send (title : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'UserMessageRpc', MethodSignature: 'BFNlbmQNU3lzdGVtLlN0cmluZw==', Arguments: [title] });
    }
}
export interface ICoreRpcProxyForUserOrganizationRpc {
    getAll (skip : number, take : number, query : string) : Promise<ListResult<OrganizationDto>>;
    getById (id : string) : Promise<OrganizationDto>;
    create (info : OrganizationInfoDto) : Promise<ResultT<string>>;
    delete (id : string) : Promise<void>;
}
export class CoreRpcProxyForUserOrganizationRpc implements ICoreRpcProxyForUserOrganizationRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (skip : number, take : number, query : string) : Promise<ListResult<OrganizationDto>>    {
        return this.parent.send<ListResult<OrganizationDto>>({Target: 'UserOrganizationRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [skip,take,query] });
    }
    public getById (id : string) : Promise<OrganizationDto>    {
        return this.parent.send<OrganizationDto>({Target: 'UserOrganizationRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public create (info : OrganizationInfoDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'UserOrganizationRpc', MethodSignature: 'BkNyZWF0ZS5VUGFuZWwuUnBjLk9yZ2FuaXphdGlvblJwYy5Pcmdhbml6YXRpb25JbmZvRHRv', Arguments: [info] });
    }
    public delete (id : string) : Promise<void>    {
        return this.parent.send<void>({Target: 'UserOrganizationRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
}
export interface ICoreRpcProxyForUserProfileRpc {
    getProfile () : Promise<UserProfileDto>;
}
export class CoreRpcProxyForUserProfileRpc implements ICoreRpcProxyForUserProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getProfile () : Promise<UserProfileDto>    {
        return this.parent.send<UserProfileDto>({Target: 'UserProfileRpc', MethodSignature: 'CkdldFByb2ZpbGU=', Arguments: [] });
    }
}
export interface ICoreRpcProxyForProjectAccountRpc {
    getAll (oid : string, pid : string, economicEntityId : string, skip : number, take : number) : Promise<ListResult<UPanelAccountDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelAccountDto>>;
    create (oid : string, pid : string, economicEntityId : string, args : UPanelAccountCreateArgsDto) : Promise<ResultT<string>>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>;
}
export class CoreRpcProxyForProjectAccountRpc implements ICoreRpcProxyForProjectAccountRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, pid : string, economicEntityId : string, skip : number, take : number) : Promise<ListResult<UPanelAccountDto>>    {
        return this.parent.send<ListResult<UPanelAccountDto>>({Target: 'ProjectAccountRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,economicEntityId,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelAccountDto>>    {
        return this.parent.send<ResultT<UPanelAccountDto>>({Target: 'ProjectAccountRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public create (oid : string, pid : string, economicEntityId : string, args : UPanelAccountCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectAccountRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZDBVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsQWNjb3VudENyZWF0ZUFyZ3NEdG8=', Arguments: [oid,pid,economicEntityId,args] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>({Target: 'ProjectAccountRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectContractRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityContractCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, eeid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityContractDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityContractDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityContractCreateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getContractTariffs (oid : string, pid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>;
    attachTariff (oid : string, pid : string, contractId : string, tariffId : string) : Promise<Result>;
}
export class CoreRpcProxyForProjectContractRpc implements ICoreRpcProxyForProjectContractRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityContractCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectContractRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD9VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDb250cmFjdENyZWF0ZUFyZ3NEdG8=', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, eeid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityContractDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityContractDto>>({Target: 'ProjectContractRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,eeid,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityContractDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityContractDto>>({Target: 'ProjectContractRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityContractCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectContractRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD9VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDb250cmFjdENyZWF0ZUFyZ3NEdG8=', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectContractRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getContractTariffs (oid : string, pid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityTariffDto>>({Target: 'ProjectContractRpc', MethodSignature: 'EkdldENvbnRyYWN0VGFyaWZmcwtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,id,skip,take] });
    }
    public attachTariff (oid : string, pid : string, contractId : string, tariffId : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectContractRpc', MethodSignature: 'DEF0dGFjaFRhcmlmZgtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,contractId,tariffId] });
    }
}
export interface ICoreRpcProxyForProjectCurrencyTypeRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityCurrencyTypeCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityCurrencyTypeDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityCurrencyTypeDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityCurrencyTypeCreateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCurrencyTypeCreateArgsDto>>;
}
export class CoreRpcProxyForProjectCurrencyTypeRpc implements ICoreRpcProxyForProjectCurrencyTypeRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityCurrencyTypeCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZENVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDdXJyZW5jeVR5cGVDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityCurrencyTypeDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityCurrencyTypeDto>>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityCurrencyTypeDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityCurrencyTypeDto>>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityCurrencyTypeCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZENVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDdXJyZW5jeVR5cGVDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCurrencyTypeCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelEconomicEntityCurrencyTypeCreateArgsDto>>({Target: 'ProjectCurrencyTypeRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectEconomicEntityRpc {
    getRootEconomicEntity (oid : string, pid : string) : Promise<ResultT<UPanelEconomicEntityDto>>;
    create (oid : string, pid : string, args : UPanelEconomicEntityCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityCreateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCreateArgsDto>>;
}
export class CoreRpcProxyForProjectEconomicEntityRpc implements ICoreRpcProxyForProjectEconomicEntityRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getRootEconomicEntity (oid : string, pid : string) : Promise<ResultT<UPanelEconomicEntityDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityDto>>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'FUdldFJvb3RFY29ub21pY0VudGl0eQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid] });
    }
    public create (oid : string, pid : string, args : UPanelEconomicEntityCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZDdVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityDto>>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityDto>>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZDdVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelEconomicEntityCreateArgsDto>>({Target: 'ProjectEconomicEntityRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectEventRpc {
    get (oid : string, pid : string, id : string) : Promise<ResultT<EventDto>>;
    getGraph (oid : string, pid : string, id : string) : Promise<ResultT<SimpleEventGraphDto>>;
    getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<EventDto>>;
}
export class CoreRpcProxyForProjectEventRpc implements ICoreRpcProxyForProjectEventRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public get (oid : string, pid : string, id : string) : Promise<ResultT<EventDto>>    {
        return this.parent.send<ResultT<EventDto>>({Target: 'ProjectEventRpc', MethodSignature: 'A0dldAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getGraph (oid : string, pid : string, id : string) : Promise<ResultT<SimpleEventGraphDto>>    {
        return this.parent.send<ResultT<SimpleEventGraphDto>>({Target: 'ProjectEventRpc', MethodSignature: 'CEdldEdyYXBoC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid,id] });
    }
    public getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<EventDto>>    {
        return this.parent.send<ListResult<EventDto>>({Target: 'ProjectEventRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,skip,take] });
    }
}
export interface ICoreRpcProxyForProjectInternalTransactionEntryRpc {
    getAll (oid : string, pid : string, subAccountId : string, type : UPanelTransactionType | null, skip : number | null, take : number | null) : Promise<ListResult<UPanelInternalTransactionEntryDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelInternalTransactionEntryDto>>;
}
export class CoreRpcProxyForProjectInternalTransactionEntryRpc implements ICoreRpcProxyForProjectInternalTransactionEntryRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, pid : string, subAccountId : string, type : UPanelTransactionType | null, skip : number | null, take : number | null) : Promise<ListResult<UPanelInternalTransactionEntryDto>>    {
        return this.parent.send<ListResult<UPanelInternalTransactionEntryDto>>({Target: 'ProjectInternalTransactionEntryRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZH9TeXN0ZW0uTnVsbGFibGVgMVtbVVBhbmVsLlJwYy5Qcm9qZWN0UnBjLlVQYW5lbFRyYW5zYWN0aW9uVHlwZSwgVVBhbmVsLCBWZXJzaW9uPTEuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49bnVsbF1dfFN5c3RlbS5OdWxsYWJsZWAxW1tTeXN0ZW0uSW50MzIsIFN5c3RlbS5Qcml2YXRlLkNvcmVMaWIsIFZlcnNpb249Ni4wLjAuMCwgQ3VsdHVyZT1uZXV0cmFsLCBQdWJsaWNLZXlUb2tlbj03Y2VjODVkN2JlYTc3OThlXV18U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [oid,pid,subAccountId,type,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelInternalTransactionEntryDto>>    {
        return this.parent.send<ResultT<UPanelInternalTransactionEntryDto>>({Target: 'ProjectInternalTransactionEntryRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
}
export interface ICoreRpcProxyForProjectProfileRpc {
    heartbeat (oid : string, pid : string) : Promise<Result>;
    getProfile (oid : string, pid : string) : Promise<UPanelProjectProfileDto>;
}
export class CoreRpcProxyForProjectProfileRpc implements ICoreRpcProxyForProjectProfileRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public heartbeat (oid : string, pid : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectProfileRpc', MethodSignature: 'CUhlYXJ0YmVhdAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid] });
    }
    public getProfile (oid : string, pid : string) : Promise<UPanelProjectProfileDto>    {
        return this.parent.send<UPanelProjectProfileDto>({Target: 'ProjectProfileRpc', MethodSignature: 'CkdldFByb2ZpbGULU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectServiceProvisionRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityServiceProvisionCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, economicEntityId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceProvisionDto>>;
}
export class CoreRpcProxyForProjectServiceProvisionRpc implements ICoreRpcProxyForProjectServiceProvisionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityServiceProvisionCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectServiceProvisionRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZEdVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlTZXJ2aWNlUHJvdmlzaW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, economicEntityId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceProvisionDto>>({Target: 'ProjectServiceProvisionRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,economicEntityId,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceProvisionDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityServiceProvisionDto>>({Target: 'ProjectServiceProvisionRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
}
export interface ICoreRpcProxyForProjectServiceRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityServiceCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>;
    getAllByContract (oid : string, pid : string, contractId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityServiceCreateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>;
}
export class CoreRpcProxyForProjectServiceRpc implements ICoreRpcProxyForProjectServiceRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityServiceCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectServiceRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD5VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlTZXJ2aWNlQ3JlYXRlQXJnc0R0bw==', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceDto>>({Target: 'ProjectServiceRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,skip,take] });
    }
    public getAllByContract (oid : string, pid : string, contractId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceDto>>({Target: 'ProjectServiceRpc', MethodSignature: 'EEdldEFsbEJ5Q29udHJhY3QLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMg==', Arguments: [oid,pid,contractId,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityServiceDto>>({Target: 'ProjectServiceRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityServiceCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectServiceRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD5VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlTZXJ2aWNlQ3JlYXRlQXJnc0R0bw==', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectServiceRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>({Target: 'ProjectServiceRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectSubAccountRpc {
    getAll (oid : string, pid : string, accountId : string, skip : number | null, take : number | null) : Promise<ListResult<UPanelSubAccountDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelSubAccountDto>>;
    create (oid : string, pid : string, accountId : string, args : UPanelSubAccountCreateArgsDto) : Promise<ResultT<string>>;
    getBalance (oid : string, pid : string, id : string) : Promise<ResultT<number>>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>;
}
export class CoreRpcProxyForProjectSubAccountRpc implements ICoreRpcProxyForProjectSubAccountRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, pid : string, accountId : string, skip : number | null, take : number | null) : Promise<ListResult<UPanelSubAccountDto>>    {
        return this.parent.send<ListResult<UPanelSubAccountDto>>({Target: 'ProjectSubAccountRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZHxTeXN0ZW0uTnVsbGFibGVgMVtbU3lzdGVtLkludDMyLCBTeXN0ZW0uUHJpdmF0ZS5Db3JlTGliLCBWZXJzaW9uPTYuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49N2NlYzg1ZDdiZWE3Nzk4ZV1dfFN5c3RlbS5OdWxsYWJsZWAxW1tTeXN0ZW0uSW50MzIsIFN5c3RlbS5Qcml2YXRlLkNvcmVMaWIsIFZlcnNpb249Ni4wLjAuMCwgQ3VsdHVyZT1uZXV0cmFsLCBQdWJsaWNLZXlUb2tlbj03Y2VjODVkN2JlYTc3OThlXV0=', Arguments: [oid,pid,accountId,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelSubAccountDto>>    {
        return this.parent.send<ResultT<UPanelSubAccountDto>>({Target: 'ProjectSubAccountRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public create (oid : string, pid : string, accountId : string, args : UPanelSubAccountCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectSubAccountRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZDNVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsU3ViQWNjb3VudENyZWF0ZUFyZ3NEdG8=', Arguments: [oid,pid,accountId,args] });
    }
    public getBalance (oid : string, pid : string, id : string) : Promise<ResultT<number>>    {
        return this.parent.send<ResultT<number>>({Target: 'ProjectSubAccountRpc', MethodSignature: 'CkdldEJhbGFuY2ULU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>({Target: 'ProjectSubAccountRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectTariffRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityTariffCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityTariffDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityTariffCreateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityTariffCreateArgsDto>>;
}
export class CoreRpcProxyForProjectTariffRpc implements ICoreRpcProxyForProjectTariffRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityTariffCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectTariffRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD1VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlUYXJpZmZDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityTariffDto>>({Target: 'ProjectTariffRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityTariffDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityTariffDto>>({Target: 'ProjectTariffRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityTariffCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectTariffRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZD1VUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlUYXJpZmZDcmVhdGVBcmdzRHRv', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectTariffRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getRemoteUi (oid : string, pid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityTariffCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelEconomicEntityTariffCreateArgsDto>>({Target: 'ProjectTariffRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlk', Arguments: [oid,pid] });
    }
}
export interface ICoreRpcProxyForProjectTariffVersionRpc {
    create (oid : string, pid : string, args : UPanelEconomicEntityTariffVersionCreateArgsDto) : Promise<ResultT<string>>;
    getAll (oid : string, pid : string, tariffId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffVersionDto>>;
    getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityTariffVersionDto>>;
    update (oid : string, pid : string, id : string, args : UPanelEconomicEntityTariffVersionUpdateArgsDto) : Promise<Result>;
    delete (oid : string, pid : string, id : string) : Promise<Result>;
    getRates (oid : string, pid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityRateProvisionDto>>;
    attachService (oid : string, pid : string, versionId : string, args : UPanelAttachServiceToTariffArgsDto) : Promise<Result>;
}
export class CoreRpcProxyForProjectTariffVersionRpc implements ICoreRpcProxyForProjectTariffVersionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, pid : string, args : UPanelEconomicEntityTariffVersionCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZERVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlUYXJpZmZWZXJzaW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [oid,pid,args] });
    }
    public getAll (oid : string, pid : string, tariffId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffVersionDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityTariffVersionDto>>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,pid,tariffId,skip,take] });
    }
    public getById (oid : string, pid : string, id : string) : Promise<ResultT<UPanelEconomicEntityTariffVersionDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityTariffVersionDto>>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,pid,id] });
    }
    public update (oid : string, pid : string, id : string, args : UPanelEconomicEntityTariffVersionUpdateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZERVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsRWNvbm9taWNFbnRpdHlUYXJpZmZWZXJzaW9uVXBkYXRlQXJnc0R0bw==', Arguments: [oid,pid,id,args] });
    }
    public delete (oid : string, pid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,pid,id] });
    }
    public getRates (oid : string, pid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityRateProvisionDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityRateProvisionDto>>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'CEdldFJhdGVzC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlkC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,pid,id,skip,take] });
    }
    public attachService (oid : string, pid : string, versionId : string, args : UPanelAttachServiceToTariffArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'ProjectTariffVersionRpc', MethodSignature: 'DUF0dGFjaFNlcnZpY2ULU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ4VVBhbmVsLlJwYy5Qcm9qZWN0UnBjLlVQYW5lbEF0dGFjaFNlcnZpY2VUb1RhcmlmZkFyZ3NEdG8=', Arguments: [oid,pid,versionId,args] });
    }
}
export interface ICoreRpcProxyForOrganizationConnectableServiceRpc {
    getServices (oid : string) : Promise<ConnectableServiceInfoDto[]>;
    getService (oid : string, serviceId : number) : Promise<ConnectableServiceInfoDto>;
    isConnected (oid : string, serviceId : number) : Promise<ResultT<boolean>>;
    connect (oid : string, serviceId : number) : Promise<ResultT<string>>;
    getToken (oid : string, serviceId : number) : Promise<ResultT<string>>;
}
export class CoreRpcProxyForOrganizationConnectableServiceRpc implements ICoreRpcProxyForOrganizationConnectableServiceRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getServices (oid : string) : Promise<ConnectableServiceInfoDto[]>    {
        return this.parent.send<ConnectableServiceInfoDto[]>({Target: 'OrganizationConnectableServiceRpc', MethodSignature: 'C0dldFNlcnZpY2VzC1N5c3RlbS5HdWlk', Arguments: [oid] });
    }
    public getService (oid : string, serviceId : number) : Promise<ConnectableServiceInfoDto>    {
        return this.parent.send<ConnectableServiceInfoDto>({Target: 'OrganizationConnectableServiceRpc', MethodSignature: 'CkdldFNlcnZpY2ULU3lzdGVtLkd1aWQMU3lzdGVtLkludDMy', Arguments: [oid,serviceId] });
    }
    public isConnected (oid : string, serviceId : number) : Promise<ResultT<boolean>>    {
        return this.parent.send<ResultT<boolean>>({Target: 'OrganizationConnectableServiceRpc', MethodSignature: 'C0lzQ29ubmVjdGVkC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMg==', Arguments: [oid,serviceId] });
    }
    public connect (oid : string, serviceId : number) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'OrganizationConnectableServiceRpc', MethodSignature: 'B0Nvbm5lY3QLU3lzdGVtLkd1aWQMU3lzdGVtLkludDMy', Arguments: [oid,serviceId] });
    }
    public getToken (oid : string, serviceId : number) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'OrganizationConnectableServiceRpc', MethodSignature: 'CEdldFRva2VuC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMg==', Arguments: [oid,serviceId] });
    }
}
export interface ICoreRpcProxyForOrganizationDocumentRpc {
    getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<DocumentDto>>;
    getById (oid : string, id : number) : Promise<DocumentDto>;
    delete (oid : string, id : number) : Promise<void>;
    create (oid : string, title : string, extension : string, file : number[]) : Promise<number>;
}
export class CoreRpcProxyForOrganizationDocumentRpc implements ICoreRpcProxyForOrganizationDocumentRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<DocumentDto>>    {
        return this.parent.send<ListResult<DocumentDto>>({Target: 'OrganizationDocumentRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [oid,skip,take,query] });
    }
    public getById (oid : string, id : number) : Promise<DocumentDto>    {
        return this.parent.send<DocumentDto>({Target: 'OrganizationDocumentRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQMU3lzdGVtLkludDMy', Arguments: [oid,id] });
    }
    public delete (oid : string, id : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'OrganizationDocumentRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzI=', Arguments: [oid,id] });
    }
    public create (oid : string, title : string, extension : string, file : number[]) : Promise<number>    {
        return this.parent.send<number>({Target: 'OrganizationDocumentRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZA1TeXN0ZW0uU3RyaW5nDVN5c3RlbS5TdHJpbmcOU3lzdGVtLkludDMyW10=', Arguments: [oid,title,extension,file] });
    }
}
export interface ICoreRpcProxyForOrganizationMessageRpc {
    getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>;
    send (oid : string, title : string) : Promise<number>;
}
export class CoreRpcProxyForOrganizationMessageRpc implements ICoreRpcProxyForOrganizationMessageRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>    {
        return this.parent.send<ListResult<MessageBalloonDto>>({Target: 'OrganizationMessageRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [oid,skip,take,query] });
    }
    public send (oid : string, title : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'OrganizationMessageRpc', MethodSignature: 'BFNlbmQLU3lzdGVtLkd1aWQNU3lzdGVtLlN0cmluZw==', Arguments: [oid,title] });
    }
}
export interface ICoreRpcProxyForOrganizationProjectRpc {
    create (oid : string, args : UPanelProjectCreateArgsDto) : Promise<ResultT<string>>;
    update (oid : string, id : string, args : UPanelProjectUpdateArgsDto) : Promise<ResultT<string>>;
    delete (oid : string, id : string) : Promise<Result>;
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelProjectDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelProjectDto>>;
    getUpdateRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelProjectUpdateArgsDto>>;
    getCreateRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelProjectCreateArgsDto>>;
}
export class CoreRpcProxyForOrganizationProjectRpc implements ICoreRpcProxyForOrganizationProjectRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public create (oid : string, args : UPanelProjectCreateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'OrganizationProjectRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZDVVUGFuZWwuUnBjLk9yZ2FuaXphdGlvblJwYy5VUGFuZWxQcm9qZWN0Q3JlYXRlQXJnc0R0bw==', Arguments: [oid,args] });
    }
    public update (oid : string, id : string, args : UPanelProjectUpdateArgsDto) : Promise<ResultT<string>>    {
        return this.parent.send<ResultT<string>>({Target: 'OrganizationProjectRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZDVVUGFuZWwuUnBjLk9yZ2FuaXphdGlvblJwYy5VUGFuZWxQcm9qZWN0VXBkYXRlQXJnc0R0bw==', Arguments: [oid,id,args] });
    }
    public delete (oid : string, id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'OrganizationProjectRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZAtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelProjectDto>>    {
        return this.parent.send<ListResult<UPanelProjectDto>>({Target: 'OrganizationProjectRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelProjectDto>>    {
        return this.parent.send<ResultT<UPanelProjectDto>>({Target: 'OrganizationProjectRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQLU3lzdGVtLkd1aWQ=', Arguments: [oid,id] });
    }
    public getUpdateRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelProjectUpdateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelProjectUpdateArgsDto>>({Target: 'OrganizationProjectRpc', MethodSignature: 'EUdldFVwZGF0ZVJlbW90ZVVpC1N5c3RlbS5HdWlk', Arguments: [oid] });
    }
    public getCreateRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelProjectCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelProjectCreateArgsDto>>({Target: 'OrganizationProjectRpc', MethodSignature: 'EUdldENyZWF0ZVJlbW90ZVVpC1N5c3RlbS5HdWlk', Arguments: [oid] });
    }
}
export interface ICoreRpcProxyForOrganizationRpc {
    get (oid : string) : Promise<OrganizationDto>;
    hasBookKeeperConnections (oid : string) : Promise<boolean>;
    update (oid : string, info : OrganizationInfoDto) : Promise<Result>;
    getRemoteUi (oid : string | null) : Promise<RemoteUiDefinitionDto<OrganizationInfoDto>>;
}
export class CoreRpcProxyForOrganizationRpc implements ICoreRpcProxyForOrganizationRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public get (oid : string) : Promise<OrganizationDto>    {
        return this.parent.send<OrganizationDto>({Target: 'OrganizationRpc', MethodSignature: 'A0dldAtTeXN0ZW0uR3VpZA==', Arguments: [oid] });
    }
    public hasBookKeeperConnections (oid : string) : Promise<boolean>    {
        return this.parent.send<boolean>({Target: 'OrganizationRpc', MethodSignature: 'GEhhc0Jvb2tLZWVwZXJDb25uZWN0aW9ucwtTeXN0ZW0uR3VpZA==', Arguments: [oid] });
    }
    public update (oid : string, info : OrganizationInfoDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'OrganizationRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZC5VUGFuZWwuUnBjLk9yZ2FuaXphdGlvblJwYy5Pcmdhbml6YXRpb25JbmZvRHRv', Arguments: [oid,info] });
    }
    public getRemoteUi (oid : string | null) : Promise<RemoteUiDefinitionDto<OrganizationInfoDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<OrganizationInfoDto>>({Target: 'OrganizationRpc', MethodSignature: 'C0dldFJlbW90ZVVpe1N5c3RlbS5OdWxsYWJsZWAxW1tTeXN0ZW0uR3VpZCwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [oid] });
    }
}
export interface ICoreRpcProxyForAdminBookKeeperConnectionRpc {
    getById (id : string) : Promise<UPanelBookKeeperConnectionDto>;
    getAll (skip : number, take : number) : Promise<ListResult<UPanelBookKeeperConnectionDto>>;
    create (args : UPanelBookKeeperConnectionCreateArgsDto) : Promise<Result>;
    update (id : string, args : UPanelBookKeeperConnectionCreateArgsDto) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelBookKeeperConnectionCreateArgsDto>>;
}
export class CoreRpcProxyForAdminBookKeeperConnectionRpc implements ICoreRpcProxyForAdminBookKeeperConnectionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : string) : Promise<UPanelBookKeeperConnectionDto>    {
        return this.parent.send<UPanelBookKeeperConnectionDto>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getAll (skip : number, take : number) : Promise<ListResult<UPanelBookKeeperConnectionDto>>    {
        return this.parent.send<ListResult<UPanelBookKeeperConnectionDto>>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public create (args : UPanelBookKeeperConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'BkNyZWF0ZTtVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEJvb2tLZWVwZXJDb25uZWN0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [args] });
    }
    public update (id : string, args : UPanelBookKeeperConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZDtVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEJvb2tLZWVwZXJDb25uZWN0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [id,args] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelBookKeeperConnectionCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelBookKeeperConnectionCreateArgsDto>>({Target: 'AdminBookKeeperConnectionRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAdminConnectableServiceRpc {
    getById (id : number) : Promise<UPanelConnectableServiceDto>;
    getAll (query : string, skip : number, take : number) : Promise<ListResult<UPanelConnectableServiceDto>>;
    create (args : UPanelConnectableServiceCreateArgsDto) : Promise<Result>;
    update (id : number, args : UPanelConnectableServiceCreateArgsDto) : Promise<Result>;
    delete (id : number) : Promise<Result>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelConnectableServiceCreateArgsDto>>;
}
export class CoreRpcProxyForAdminConnectableServiceRpc implements ICoreRpcProxyForAdminConnectableServiceRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : number) : Promise<UPanelConnectableServiceDto>    {
        return this.parent.send<UPanelConnectableServiceDto>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public getAll (query : string, skip : number, take : number) : Promise<ListResult<UPanelConnectableServiceDto>>    {
        return this.parent.send<ListResult<UPanelConnectableServiceDto>>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [query,skip,take] });
    }
    public create (args : UPanelConnectableServiceCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'BkNyZWF0ZTlVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbENvbm5lY3RhYmxlU2VydmljZUNyZWF0ZUFyZ3NEdG8=', Arguments: [args] });
    }
    public update (id : number, args : UPanelConnectableServiceCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'BlVwZGF0ZQxTeXN0ZW0uSW50MzI5VVBhbmVsLlJwYy5BZG1pblJwYy5VUGFuZWxDb25uZWN0YWJsZVNlcnZpY2VDcmVhdGVBcmdzRHRv', Arguments: [id,args] });
    }
    public delete (id : number) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'BkRlbGV0ZQxTeXN0ZW0uSW50MzI=', Arguments: [id] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelConnectableServiceCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelConnectableServiceCreateArgsDto>>({Target: 'AdminConnectableServiceRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc {
    getById (id : string) : Promise<UPanelOrganizationBookKeeperConnectionDto>;
    getAll (skip : number, take : number) : Promise<ListResult<UPanelOrganizationBookKeeperConnectionDto>>;
    create (args : UPanelOrganizationBookKeeperConnectionCreateArgsDto) : Promise<Result>;
    update (id : string, args : UPanelOrganizationBookKeeperConnectionCreateArgsDto) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelOrganizationBookKeeperConnectionCreateArgsDto>>;
}
export class CoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc implements ICoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : string) : Promise<UPanelOrganizationBookKeeperConnectionDto>    {
        return this.parent.send<UPanelOrganizationBookKeeperConnectionDto>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getAll (skip : number, take : number) : Promise<ListResult<UPanelOrganizationBookKeeperConnectionDto>>    {
        return this.parent.send<ListResult<UPanelOrganizationBookKeeperConnectionDto>>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public create (args : UPanelOrganizationBookKeeperConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'BkNyZWF0ZUdVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbE9yZ2FuaXphdGlvbkJvb2tLZWVwZXJDb25uZWN0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [args] });
    }
    public update (id : string, args : UPanelOrganizationBookKeeperConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZEdVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbE9yZ2FuaXphdGlvbkJvb2tLZWVwZXJDb25uZWN0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [id,args] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelOrganizationBookKeeperConnectionCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelOrganizationBookKeeperConnectionCreateArgsDto>>({Target: 'AdminOrganizationBookKeeperConnectionRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAdminOrganizationMessageRpc {
    getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>;
    send (oid : string, title : string) : Promise<number>;
}
export class CoreRpcProxyForAdminOrganizationMessageRpc implements ICoreRpcProxyForAdminOrganizationMessageRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>    {
        return this.parent.send<ListResult<MessageBalloonDto>>({Target: 'AdminOrganizationMessageRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [oid,skip,take,query] });
    }
    public send (oid : string, title : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'AdminOrganizationMessageRpc', MethodSignature: 'BFNlbmQLU3lzdGVtLkd1aWQNU3lzdGVtLlN0cmluZw==', Arguments: [oid,title] });
    }
}
export interface ICoreRpcProxyForAdminOrganizationRpc {
    getById (id : string) : Promise<UPanelAdminOrganizationDto>;
    getAll (skip : number, take : number) : Promise<ListResult<UPanelAdminOrganizationDto>>;
    update (id : string, args : UPanelAdminOrganizationCreateArgsDto) : Promise<Result>;
    create (args : UPanelAdminOrganizationCreateArgsDto) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    getConnectedServices (oid : string) : Promise<ConnectableServiceInfoDto[]>;
    connectService (oid : string, serviceId : number) : Promise<void>;
    disconnectService (oid : string, serviceId : number) : Promise<void>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelAdminOrganizationCreateArgsDto>>;
}
export class CoreRpcProxyForAdminOrganizationRpc implements ICoreRpcProxyForAdminOrganizationRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : string) : Promise<UPanelAdminOrganizationDto>    {
        return this.parent.send<UPanelAdminOrganizationDto>({Target: 'AdminOrganizationRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getAll (skip : number, take : number) : Promise<ListResult<UPanelAdminOrganizationDto>>    {
        return this.parent.send<ListResult<UPanelAdminOrganizationDto>>({Target: 'AdminOrganizationRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public update (id : string, args : UPanelAdminOrganizationCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZDhVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFkbWluT3JnYW5pemF0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [id,args] });
    }
    public create (args : UPanelAdminOrganizationCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationRpc', MethodSignature: 'BkNyZWF0ZThVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFkbWluT3JnYW5pemF0aW9uQ3JlYXRlQXJnc0R0bw==', Arguments: [args] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminOrganizationRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public getConnectedServices (oid : string) : Promise<ConnectableServiceInfoDto[]>    {
        return this.parent.send<ConnectableServiceInfoDto[]>({Target: 'AdminOrganizationRpc', MethodSignature: 'FEdldENvbm5lY3RlZFNlcnZpY2VzC1N5c3RlbS5HdWlk', Arguments: [oid] });
    }
    public connectService (oid : string, serviceId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'AdminOrganizationRpc', MethodSignature: 'DkNvbm5lY3RTZXJ2aWNlC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMg==', Arguments: [oid,serviceId] });
    }
    public disconnectService (oid : string, serviceId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'AdminOrganizationRpc', MethodSignature: 'EURpc2Nvbm5lY3RTZXJ2aWNlC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMg==', Arguments: [oid,serviceId] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelAdminOrganizationCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelAdminOrganizationCreateArgsDto>>({Target: 'AdminOrganizationRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAdminProjectRpc {
    getById (id : string) : Promise<UPanelAdminProjectDto>;
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelAdminProjectDto>>;
    create (oid : string, args : UPanelAdminProjectCreateArgsDto) : Promise<Result>;
    update (id : string, args : UPanelAdminProjectCreateArgsDto) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelAdminProjectCreateArgsDto>>;
}
export class CoreRpcProxyForAdminProjectRpc implements ICoreRpcProxyForAdminProjectRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : string) : Promise<UPanelAdminProjectDto>    {
        return this.parent.send<UPanelAdminProjectDto>({Target: 'AdminProjectRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelAdminProjectDto>>    {
        return this.parent.send<ListResult<UPanelAdminProjectDto>>({Target: 'AdminProjectRpc', MethodSignature: 'BkdldEFsbAtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,skip,take] });
    }
    public create (oid : string, args : UPanelAdminProjectCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminProjectRpc', MethodSignature: 'BkNyZWF0ZQtTeXN0ZW0uR3VpZDNVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFkbWluUHJvamVjdENyZWF0ZUFyZ3NEdG8=', Arguments: [oid,args] });
    }
    public update (id : string, args : UPanelAdminProjectCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminProjectRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZDNVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFkbWluUHJvamVjdENyZWF0ZUFyZ3NEdG8=', Arguments: [id,args] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminProjectRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelAdminProjectCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelAdminProjectCreateArgsDto>>({Target: 'AdminProjectRpc', MethodSignature: 'C0dldFJlbW90ZVVpC1N5c3RlbS5HdWlk', Arguments: [oid] });
    }
}
export interface ICoreRpcProxyForAdminUPanelApiConnectionRpc {
    getById (id : string) : Promise<UPanelApiConnectionDto>;
    getAll (skip : number, take : number) : Promise<ListResult<UPanelApiConnectionDto>>;
    create (args : UPanelApiConnectionCreateArgsDto) : Promise<Result>;
    update (id : string, args : UPanelApiConnectionCreateArgsDto) : Promise<Result>;
    delete (id : string) : Promise<Result>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelApiConnectionCreateArgsDto>>;
}
export class CoreRpcProxyForAdminUPanelApiConnectionRpc implements ICoreRpcProxyForAdminUPanelApiConnectionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : string) : Promise<UPanelApiConnectionDto>    {
        return this.parent.send<UPanelApiConnectionDto>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'B0dldEJ5SWQLU3lzdGVtLkd1aWQ=', Arguments: [id] });
    }
    public getAll (skip : number, take : number) : Promise<ListResult<UPanelApiConnectionDto>>    {
        return this.parent.send<ListResult<UPanelApiConnectionDto>>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public create (args : UPanelApiConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'BkNyZWF0ZTRVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFwaUNvbm5lY3Rpb25DcmVhdGVBcmdzRHRv', Arguments: [args] });
    }
    public update (id : string, args : UPanelApiConnectionCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'BlVwZGF0ZQtTeXN0ZW0uR3VpZDRVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFwaUNvbm5lY3Rpb25DcmVhdGVBcmdzRHRv', Arguments: [id,args] });
    }
    public delete (id : string) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'BkRlbGV0ZQtTeXN0ZW0uR3VpZA==', Arguments: [id] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelApiConnectionCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelApiConnectionCreateArgsDto>>({Target: 'AdminUPanelApiConnectionRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAdminUserMessageRpc {
    getAll (userId : number, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>;
    send (userId : number, title : string) : Promise<number>;
}
export class CoreRpcProxyForAdminUserMessageRpc implements ICoreRpcProxyForAdminUserMessageRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (userId : number, skip : number, take : number, query : string) : Promise<ListResult<MessageBalloonDto>>    {
        return this.parent.send<ListResult<MessageBalloonDto>>({Target: 'AdminUserMessageRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMyDFN5c3RlbS5JbnQzMg1TeXN0ZW0uU3RyaW5n', Arguments: [userId,skip,take,query] });
    }
    public send (userId : number, title : string) : Promise<number>    {
        return this.parent.send<number>({Target: 'AdminUserMessageRpc', MethodSignature: 'BFNlbmQMU3lzdGVtLkludDMyDVN5c3RlbS5TdHJpbmc=', Arguments: [userId,title] });
    }
}
export interface ICoreRpcProxyForAdminUserRpc {
    getById (id : number) : Promise<UPanelAdminUserDto>;
    getAll (skip : number, take : number) : Promise<ListResult<UPanelAdminUserDto>>;
    create (args : UPanelAdminUserCreateArgsDto) : Promise<Result>;
    update (userId : number, args : UPanelAdminUserCreateArgsDto) : Promise<Result>;
    delete (userId : number) : Promise<Result>;
    getConnectedServices (userId : number) : Promise<ConnectableServiceInfoDto[]>;
    connectService (userId : number, serviceId : number) : Promise<void>;
    disconnectService (userId : number, serviceId : number) : Promise<void>;
    getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelAdminUserCreateArgsDto>>;
}
export class CoreRpcProxyForAdminUserRpc implements ICoreRpcProxyForAdminUserRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getById (id : number) : Promise<UPanelAdminUserDto>    {
        return this.parent.send<UPanelAdminUserDto>({Target: 'AdminUserRpc', MethodSignature: 'B0dldEJ5SWQMU3lzdGVtLkludDMy', Arguments: [id] });
    }
    public getAll (skip : number, take : number) : Promise<ListResult<UPanelAdminUserDto>>    {
        return this.parent.send<ListResult<UPanelAdminUserDto>>({Target: 'AdminUserRpc', MethodSignature: 'BkdldEFsbAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [skip,take] });
    }
    public create (args : UPanelAdminUserCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUserRpc', MethodSignature: 'BkNyZWF0ZTBVUGFuZWwuUnBjLkFkbWluUnBjLlVQYW5lbEFkbWluVXNlckNyZWF0ZUFyZ3NEdG8=', Arguments: [args] });
    }
    public update (userId : number, args : UPanelAdminUserCreateArgsDto) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUserRpc', MethodSignature: 'BlVwZGF0ZQxTeXN0ZW0uSW50MzIwVVBhbmVsLlJwYy5BZG1pblJwYy5VUGFuZWxBZG1pblVzZXJDcmVhdGVBcmdzRHRv', Arguments: [userId,args] });
    }
    public delete (userId : number) : Promise<Result>    {
        return this.parent.send<Result>({Target: 'AdminUserRpc', MethodSignature: 'BkRlbGV0ZQxTeXN0ZW0uSW50MzI=', Arguments: [userId] });
    }
    public getConnectedServices (userId : number) : Promise<ConnectableServiceInfoDto[]>    {
        return this.parent.send<ConnectableServiceInfoDto[]>({Target: 'AdminUserRpc', MethodSignature: 'FEdldENvbm5lY3RlZFNlcnZpY2VzDFN5c3RlbS5JbnQzMg==', Arguments: [userId] });
    }
    public connectService (userId : number, serviceId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'AdminUserRpc', MethodSignature: 'DkNvbm5lY3RTZXJ2aWNlDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [userId,serviceId] });
    }
    public disconnectService (userId : number, serviceId : number) : Promise<void>    {
        return this.parent.send<void>({Target: 'AdminUserRpc', MethodSignature: 'EURpc2Nvbm5lY3RTZXJ2aWNlDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [userId,serviceId] });
    }
    public getRemoteUi () : Promise<RemoteUiDefinitionDto<UPanelAdminUserCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelAdminUserCreateArgsDto>>({Target: 'AdminUserRpc', MethodSignature: 'C0dldFJlbW90ZVVp', Arguments: [] });
    }
}
export interface ICoreRpcProxyForAccountingAccountRpc {
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelAccountDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelAccountDto>>;
    getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>;
}
export class CoreRpcProxyForAccountingAccountRpc implements ICoreRpcProxyForAccountingAccountRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelAccountDto>>    {
        return this.parent.send<ListResult<UPanelAccountDto>>({Target: 'AccountingAccountRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelAccountDto>>    {
        return this.parent.send<ResultT<UPanelAccountDto>>({Target: 'AccountingAccountRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelAccountCreateArgsDto>>({Target: 'AccountingAccountRpc', MethodSignature: 'C0dldFJlbW90ZVVpDVN5c3RlbS5TdHJpbmc=', Arguments: [oid] });
    }
}
export interface ICoreRpcProxyForAccountingContractRpc {
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityContractDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityContractDto>>;
    getContractTariffs (oid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>;
}
export class CoreRpcProxyForAccountingContractRpc implements ICoreRpcProxyForAccountingContractRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityContractDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityContractDto>>({Target: 'AccountingContractRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityContractDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityContractDto>>({Target: 'AccountingContractRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getContractTariffs (oid : string, id : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityTariffDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityTariffDto>>({Target: 'AccountingContractRpc', MethodSignature: 'EkdldENvbnRyYWN0VGFyaWZmcw1TeXN0ZW0uU3RyaW5nC1N5c3RlbS5HdWlkDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,id,skip,take] });
    }
}
export interface ICoreRpcProxyForAccountingEconomicEntityRpc {
    getCurrent (oid : string) : Promise<ResultT<UPanelEconomicEntityDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityDto>>;
}
export class CoreRpcProxyForAccountingEconomicEntityRpc implements ICoreRpcProxyForAccountingEconomicEntityRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getCurrent (oid : string) : Promise<ResultT<UPanelEconomicEntityDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityDto>>({Target: 'AccountingEconomicEntityRpc', MethodSignature: 'CkdldEN1cnJlbnQNU3lzdGVtLlN0cmluZw==', Arguments: [oid] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityDto>>({Target: 'AccountingEconomicEntityRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
}
export interface ICoreRpcProxyForAccountingInternalTransactionEntryRpc {
    getAll (oid : string, subAccountId : string, type : UPanelTransactionType | null, skip : number | null, take : number | null) : Promise<ListResult<UPanelInternalTransactionEntryDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelInternalTransactionEntryDto>>;
}
export class CoreRpcProxyForAccountingInternalTransactionEntryRpc implements ICoreRpcProxyForAccountingInternalTransactionEntryRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, subAccountId : string, type : UPanelTransactionType | null, skip : number | null, take : number | null) : Promise<ListResult<UPanelInternalTransactionEntryDto>>    {
        return this.parent.send<ListResult<UPanelInternalTransactionEntryDto>>({Target: 'AccountingInternalTransactionEntryRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nC1N5c3RlbS5HdWlkf1N5c3RlbS5OdWxsYWJsZWAxW1tVUGFuZWwuUnBjLlByb2plY3RScGMuVVBhbmVsVHJhbnNhY3Rpb25UeXBlLCBVUGFuZWwsIFZlcnNpb249MS4wLjAuMCwgQ3VsdHVyZT1uZXV0cmFsLCBQdWJsaWNLZXlUb2tlbj1udWxsXV18U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXXxTeXN0ZW0uTnVsbGFibGVgMVtbU3lzdGVtLkludDMyLCBTeXN0ZW0uUHJpdmF0ZS5Db3JlTGliLCBWZXJzaW9uPTYuMC4wLjAsIEN1bHR1cmU9bmV1dHJhbCwgUHVibGljS2V5VG9rZW49N2NlYzg1ZDdiZWE3Nzk4ZV1d', Arguments: [oid,subAccountId,type,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelInternalTransactionEntryDto>>    {
        return this.parent.send<ResultT<UPanelInternalTransactionEntryDto>>({Target: 'AccountingInternalTransactionEntryRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
}
export interface ICoreRpcProxyForAccountingServiceProvisionRpc {
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceProvisionDto>>;
}
export class CoreRpcProxyForAccountingServiceProvisionRpc implements ICoreRpcProxyForAccountingServiceProvisionRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceProvisionDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceProvisionDto>>({Target: 'AccountingServiceProvisionRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceProvisionDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityServiceProvisionDto>>({Target: 'AccountingServiceProvisionRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
}
export interface ICoreRpcProxyForAccountingServiceRpc {
    getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>;
    getAllByContract (oid : string, contractId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceDto>>;
    getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>;
}
export class CoreRpcProxyForAccountingServiceRpc implements ICoreRpcProxyForAccountingServiceRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceDto>>({Target: 'AccountingServiceRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nDFN5c3RlbS5JbnQzMgxTeXN0ZW0uSW50MzI=', Arguments: [oid,skip,take] });
    }
    public getAllByContract (oid : string, contractId : string, skip : number, take : number) : Promise<ListResult<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ListResult<UPanelEconomicEntityServiceDto>>({Target: 'AccountingServiceRpc', MethodSignature: 'EEdldEFsbEJ5Q29udHJhY3QNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZAxTeXN0ZW0uSW50MzIMU3lzdGVtLkludDMy', Arguments: [oid,contractId,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelEconomicEntityServiceDto>>    {
        return this.parent.send<ResultT<UPanelEconomicEntityServiceDto>>({Target: 'AccountingServiceRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelEconomicEntityServiceCreateArgsDto>>({Target: 'AccountingServiceRpc', MethodSignature: 'C0dldFJlbW90ZVVpDVN5c3RlbS5TdHJpbmc=', Arguments: [oid] });
    }
}
export interface ICoreRpcProxyForAccountingSubAccountRpc {
    getAll (oid : string, accountId : string, skip : number | null, take : number | null) : Promise<ListResult<UPanelSubAccountDto>>;
    getById (oid : string, id : string) : Promise<ResultT<UPanelSubAccountDto>>;
    getBalance (oid : string, id : string) : Promise<ResultT<number>>;
    getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>;
}
export class CoreRpcProxyForAccountingSubAccountRpc implements ICoreRpcProxyForAccountingSubAccountRpc {
    private parent: CoreApi;
    constructor (parent: CoreApi){this.parent = parent;}
    public getAll (oid : string, accountId : string, skip : number | null, take : number | null) : Promise<ListResult<UPanelSubAccountDto>>    {
        return this.parent.send<ListResult<UPanelSubAccountDto>>({Target: 'AccountingSubAccountRpc', MethodSignature: 'BkdldEFsbA1TeXN0ZW0uU3RyaW5nC1N5c3RlbS5HdWlkfFN5c3RlbS5OdWxsYWJsZWAxW1tTeXN0ZW0uSW50MzIsIFN5c3RlbS5Qcml2YXRlLkNvcmVMaWIsIFZlcnNpb249Ni4wLjAuMCwgQ3VsdHVyZT1uZXV0cmFsLCBQdWJsaWNLZXlUb2tlbj03Y2VjODVkN2JlYTc3OThlXV18U3lzdGVtLk51bGxhYmxlYDFbW1N5c3RlbS5JbnQzMiwgU3lzdGVtLlByaXZhdGUuQ29yZUxpYiwgVmVyc2lvbj02LjAuMC4wLCBDdWx0dXJlPW5ldXRyYWwsIFB1YmxpY0tleVRva2VuPTdjZWM4NWQ3YmVhNzc5OGVdXQ==', Arguments: [oid,accountId,skip,take] });
    }
    public getById (oid : string, id : string) : Promise<ResultT<UPanelSubAccountDto>>    {
        return this.parent.send<ResultT<UPanelSubAccountDto>>({Target: 'AccountingSubAccountRpc', MethodSignature: 'B0dldEJ5SWQNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getBalance (oid : string, id : string) : Promise<ResultT<number>>    {
        return this.parent.send<ResultT<number>>({Target: 'AccountingSubAccountRpc', MethodSignature: 'CkdldEJhbGFuY2UNU3lzdGVtLlN0cmluZwtTeXN0ZW0uR3VpZA==', Arguments: [oid,id] });
    }
    public getRemoteUi (oid : string) : Promise<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>    {
        return this.parent.send<RemoteUiDefinitionDto<UPanelSubAccountCreateArgsDto>>({Target: 'AccountingSubAccountRpc', MethodSignature: 'C0dldFJlbW90ZVVpDVN5c3RlbS5TdHJpbmc=', Arguments: [oid] });
    }
}
export interface ICoreApi {
    userConnectableService : ICoreRpcProxyForUserConnectableServiceRpc;
    userDocument : ICoreRpcProxyForUserDocumentRpc;
    userLogin : ICoreRpcProxyForUserLoginRpc;
    userMessage : ICoreRpcProxyForUserMessageRpc;
    userOrganization : ICoreRpcProxyForUserOrganizationRpc;
    userProfile : ICoreRpcProxyForUserProfileRpc;
    projectAccount : ICoreRpcProxyForProjectAccountRpc;
    projectContract : ICoreRpcProxyForProjectContractRpc;
    projectCurrencyType : ICoreRpcProxyForProjectCurrencyTypeRpc;
    projectEconomicEntity : ICoreRpcProxyForProjectEconomicEntityRpc;
    projectEvent : ICoreRpcProxyForProjectEventRpc;
    projectInternalTransactionEntry : ICoreRpcProxyForProjectInternalTransactionEntryRpc;
    projectProfile : ICoreRpcProxyForProjectProfileRpc;
    projectServiceProvision : ICoreRpcProxyForProjectServiceProvisionRpc;
    projectService : ICoreRpcProxyForProjectServiceRpc;
    projectSubAccount : ICoreRpcProxyForProjectSubAccountRpc;
    projectTariff : ICoreRpcProxyForProjectTariffRpc;
    projectTariffVersion : ICoreRpcProxyForProjectTariffVersionRpc;
    organizationConnectableService : ICoreRpcProxyForOrganizationConnectableServiceRpc;
    organizationDocument : ICoreRpcProxyForOrganizationDocumentRpc;
    organizationMessage : ICoreRpcProxyForOrganizationMessageRpc;
    organizationProject : ICoreRpcProxyForOrganizationProjectRpc;
    organization : ICoreRpcProxyForOrganizationRpc;
    adminBookKeeperConnection : ICoreRpcProxyForAdminBookKeeperConnectionRpc;
    adminConnectableService : ICoreRpcProxyForAdminConnectableServiceRpc;
    adminOrganizationBookKeeperConnection : ICoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc;
    adminOrganizationMessage : ICoreRpcProxyForAdminOrganizationMessageRpc;
    adminOrganization : ICoreRpcProxyForAdminOrganizationRpc;
    adminProject : ICoreRpcProxyForAdminProjectRpc;
    adminUPanelApiConnection : ICoreRpcProxyForAdminUPanelApiConnectionRpc;
    adminUserMessage : ICoreRpcProxyForAdminUserMessageRpc;
    adminUser : ICoreRpcProxyForAdminUserRpc;
    accountingAccount : ICoreRpcProxyForAccountingAccountRpc;
    accountingContract : ICoreRpcProxyForAccountingContractRpc;
    accountingEconomicEntity : ICoreRpcProxyForAccountingEconomicEntityRpc;
    accountingInternalTransactionEntry : ICoreRpcProxyForAccountingInternalTransactionEntryRpc;
    accountingServiceProvision : ICoreRpcProxyForAccountingServiceProvisionRpc;
    accountingService : ICoreRpcProxyForAccountingServiceRpc;
    accountingSubAccount : ICoreRpcProxyForAccountingSubAccountRpc;
}
export class CoreApi implements ICoreApi {
    private baseUrl: string;
    private fetch: (url: string, init: RequestInit) => Promise<Response>;
    constructor(baseUrl : string, customFetch?: (url: string, init: RequestInit) => Promise<Response>) {
    this.baseUrl = baseUrl;
    if(customFetch) this.fetch = customFetch; else this.fetch =  (r, i) => fetch(r, i);
    this.userConnectableService = new CoreRpcProxyForUserConnectableServiceRpc(this);
    this.userDocument = new CoreRpcProxyForUserDocumentRpc(this);
    this.userLogin = new CoreRpcProxyForUserLoginRpc(this);
    this.userMessage = new CoreRpcProxyForUserMessageRpc(this);
    this.userOrganization = new CoreRpcProxyForUserOrganizationRpc(this);
    this.userProfile = new CoreRpcProxyForUserProfileRpc(this);
    this.projectAccount = new CoreRpcProxyForProjectAccountRpc(this);
    this.projectContract = new CoreRpcProxyForProjectContractRpc(this);
    this.projectCurrencyType = new CoreRpcProxyForProjectCurrencyTypeRpc(this);
    this.projectEconomicEntity = new CoreRpcProxyForProjectEconomicEntityRpc(this);
    this.projectEvent = new CoreRpcProxyForProjectEventRpc(this);
    this.projectInternalTransactionEntry = new CoreRpcProxyForProjectInternalTransactionEntryRpc(this);
    this.projectProfile = new CoreRpcProxyForProjectProfileRpc(this);
    this.projectServiceProvision = new CoreRpcProxyForProjectServiceProvisionRpc(this);
    this.projectService = new CoreRpcProxyForProjectServiceRpc(this);
    this.projectSubAccount = new CoreRpcProxyForProjectSubAccountRpc(this);
    this.projectTariff = new CoreRpcProxyForProjectTariffRpc(this);
    this.projectTariffVersion = new CoreRpcProxyForProjectTariffVersionRpc(this);
    this.organizationConnectableService = new CoreRpcProxyForOrganizationConnectableServiceRpc(this);
    this.organizationDocument = new CoreRpcProxyForOrganizationDocumentRpc(this);
    this.organizationMessage = new CoreRpcProxyForOrganizationMessageRpc(this);
    this.organizationProject = new CoreRpcProxyForOrganizationProjectRpc(this);
    this.organization = new CoreRpcProxyForOrganizationRpc(this);
    this.adminBookKeeperConnection = new CoreRpcProxyForAdminBookKeeperConnectionRpc(this);
    this.adminConnectableService = new CoreRpcProxyForAdminConnectableServiceRpc(this);
    this.adminOrganizationBookKeeperConnection = new CoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc(this);
    this.adminOrganizationMessage = new CoreRpcProxyForAdminOrganizationMessageRpc(this);
    this.adminOrganization = new CoreRpcProxyForAdminOrganizationRpc(this);
    this.adminProject = new CoreRpcProxyForAdminProjectRpc(this);
    this.adminUPanelApiConnection = new CoreRpcProxyForAdminUPanelApiConnectionRpc(this);
    this.adminUserMessage = new CoreRpcProxyForAdminUserMessageRpc(this);
    this.adminUser = new CoreRpcProxyForAdminUserRpc(this);
    this.accountingAccount = new CoreRpcProxyForAccountingAccountRpc(this);
    this.accountingContract = new CoreRpcProxyForAccountingContractRpc(this);
    this.accountingEconomicEntity = new CoreRpcProxyForAccountingEconomicEntityRpc(this);
    this.accountingInternalTransactionEntry = new CoreRpcProxyForAccountingInternalTransactionEntryRpc(this);
    this.accountingServiceProvision = new CoreRpcProxyForAccountingServiceProvisionRpc(this);
    this.accountingService = new CoreRpcProxyForAccountingServiceRpc(this);
    this.accountingSubAccount = new CoreRpcProxyForAccountingSubAccountRpc(this);
    }
    public send<T>(request: any) : Promise<T>{
    return this.fetch(this.baseUrl, {method: 'post', body: JSON.stringify(request)})
        .then(response => {
            if (!response.ok)
                throw new Error(response.statusText);
             return response.json();
        }).then(jr => {
            const r = <{Result?: T, Exception?: string}>jr;
            if(r.Exception) throw r.Exception;
            return r.Result!;
        });
    }
    userConnectableService : CoreRpcProxyForUserConnectableServiceRpc;
    userDocument : CoreRpcProxyForUserDocumentRpc;
    userLogin : CoreRpcProxyForUserLoginRpc;
    userMessage : CoreRpcProxyForUserMessageRpc;
    userOrganization : CoreRpcProxyForUserOrganizationRpc;
    userProfile : CoreRpcProxyForUserProfileRpc;
    projectAccount : CoreRpcProxyForProjectAccountRpc;
    projectContract : CoreRpcProxyForProjectContractRpc;
    projectCurrencyType : CoreRpcProxyForProjectCurrencyTypeRpc;
    projectEconomicEntity : CoreRpcProxyForProjectEconomicEntityRpc;
    projectEvent : CoreRpcProxyForProjectEventRpc;
    projectInternalTransactionEntry : CoreRpcProxyForProjectInternalTransactionEntryRpc;
    projectProfile : CoreRpcProxyForProjectProfileRpc;
    projectServiceProvision : CoreRpcProxyForProjectServiceProvisionRpc;
    projectService : CoreRpcProxyForProjectServiceRpc;
    projectSubAccount : CoreRpcProxyForProjectSubAccountRpc;
    projectTariff : CoreRpcProxyForProjectTariffRpc;
    projectTariffVersion : CoreRpcProxyForProjectTariffVersionRpc;
    organizationConnectableService : CoreRpcProxyForOrganizationConnectableServiceRpc;
    organizationDocument : CoreRpcProxyForOrganizationDocumentRpc;
    organizationMessage : CoreRpcProxyForOrganizationMessageRpc;
    organizationProject : CoreRpcProxyForOrganizationProjectRpc;
    organization : CoreRpcProxyForOrganizationRpc;
    adminBookKeeperConnection : CoreRpcProxyForAdminBookKeeperConnectionRpc;
    adminConnectableService : CoreRpcProxyForAdminConnectableServiceRpc;
    adminOrganizationBookKeeperConnection : CoreRpcProxyForAdminOrganizationBookKeeperConnectionRpc;
    adminOrganizationMessage : CoreRpcProxyForAdminOrganizationMessageRpc;
    adminOrganization : CoreRpcProxyForAdminOrganizationRpc;
    adminProject : CoreRpcProxyForAdminProjectRpc;
    adminUPanelApiConnection : CoreRpcProxyForAdminUPanelApiConnectionRpc;
    adminUserMessage : CoreRpcProxyForAdminUserMessageRpc;
    adminUser : CoreRpcProxyForAdminUserRpc;
    accountingAccount : CoreRpcProxyForAccountingAccountRpc;
    accountingContract : CoreRpcProxyForAccountingContractRpc;
    accountingEconomicEntity : CoreRpcProxyForAccountingEconomicEntityRpc;
    accountingInternalTransactionEntry : CoreRpcProxyForAccountingInternalTransactionEntryRpc;
    accountingServiceProvision : CoreRpcProxyForAccountingServiceProvisionRpc;
    accountingService : CoreRpcProxyForAccountingServiceRpc;
    accountingSubAccount : CoreRpcProxyForAccountingSubAccountRpc;
}
