import { action, observable } from "mobx";
import {OrganizationInfoDto, Result} from "src/api";
import { RemoteUiEditorStore } from "@kekekeks/remoteui";
import { RootStore } from "src/stores/RootStore";

export class ConfigureOrganizationStore {
    @observable editor?: RemoteUiEditorStore;
    @observable isBusy: boolean = true;
    @observable id: string | null = null;

    constructor(private readonly root: RootStore,
                private readonly doSave: (id: string | null, dto: OrganizationInfoDto) => Promise<Result>) {}

    @action async save() {
        this.isBusy = true;
        try {
            if (!this.editor) return;
            const data = await this.editor.getDataAsync() as OrganizationInfoDto;
            const response = await this.doSave(this.id, data);
            if (response.success) return;
            if (response.error.code === "ValidationError") {
                const errors = JSON.parse(response.error.description);
                this.editor.setErrors(errors);
            } else {
                alert(response.error.description);
            }
        } finally {
            this.isBusy = false;
        }
    }

    @action async load(id: string | null) {
        const definition = await this.root.userRpc.organization.getRemoteUi(id);
        this.editor = new RemoteUiEditorStore(definition.definition, definition.value);
        this.isBusy = false;
        this.id = id;
    }
}
