import { RootStore } from "src/stores/RootStore";
import { ConfigureOrganizationStore } from "src/components/ConfigureOrganization/ConfigureOrganizationStore";
import { ConnectableServiceInfoDto, OrganizationDto } from "src/api";
import { action, computed, observable } from "mobx";

type OrganizationRouteArgs = {
    oid: string;
};

export class OrganizationStore {
    @observable hasBookKeeperConnections: boolean = false;
    @observable organization?: OrganizationDto;
    @observable connectableServices: ConnectableServiceInfoDto[] = [];
    @observable configure: ConfigureOrganizationStore;
    @observable args?: OrganizationRouteArgs;

    constructor(private readonly root: RootStore) {
        this.configure = new ConfigureOrganizationStore(root, async (id, dto) => {
            if (!id) return { success: false, error: { code: "", description: "Bad id." } };
            const response = await this.root.userRpc.organization.update(id, dto);
            if (response.success) await this.root.organizationStore.load({ oid: id });
            return response;
        });
    }

    @computed get oid(): string {
        return this.organization?.id ?? "";
    }

    @action
    async load(args: OrganizationRouteArgs): Promise<void> {
        this.args = args;
        this.organization = await this.root.userRpc.organization.get(args.oid);
        this.connectableServices = await this.root.userRpc.organizationConnectableService.getServices(args.oid);
        this.hasBookKeeperConnections = await this.root.userRpc.organization.hasBookKeeperConnections(args.oid);
        await this.configure.load(this.organization?.id);
    }
}
