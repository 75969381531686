import * as React from "react";
import { FC } from "react";
import { RouterLink, RouterView } from "mobx-state-router";
import { useRootStore } from "src/utilities";
import { OrgRouteNames, OrgViewMap } from "src/pages/org/OrganizationRoutes";
import { useObserver } from "mobx-react";
import { SuspensePlaceholder } from "src/components/SuspensePlaceholder/SuspensePlaceholder";
import { LocalizedNavigationDelimiter, NavigationSidebar } from "src/components/NavigationSidebar/NavigationSidebar";
import navigationStyle from "src/components/NavigationSidebar/navigation-sidebar.module.css";
import style from "../../styles/page.module.css";
import iconGroup from "src/assets/icon/groups.svg";
import iconPerson from "src/assets/icon/person.svg";
import iconLogOut from "src/assets/icon/meeting_room.svg";
import iconDoc from "src/assets/icon/menu-doc.svg";
import iconFolder from "src/assets/icon/folder.svg";
import { Logo } from "src/components/UI/Logo/Logo";
import { AccountSelect, AccountSelectStore } from "src/components/AccountSelector/AccountSelector";
import { OrganizationStore } from "src/stores/org/OrganizationStore";
import { useIntl } from "react-intl";
import { LocalizedText, useLocalizedText } from "src/locales/LocalizedText";
import { AccountingRouteNames, AccountingViewMap } from "src/pages/accounting/AccountingRoutes";
import iconList from "src/assets/icon/list.svg";
import iconPayment from "src/assets/icon/payment.svg";
import iconShopTwo from "src/assets/icon/shop_two.svg";
import iconShop from "src/assets/icon/shop.svg";

const OrgNavigation: FC<{
    title: string;
    description: string;
    onLogout: () => void;
    organizationStore: OrganizationStore;
    accountSelectStore: AccountSelectStore;
}> = ({ title, description, onLogout, organizationStore, accountSelectStore }) => {
    const intl = useIntl();
    return (
        <NavigationSidebar>
            <AccountSelect store={accountSelectStore}>
                <Logo color={"dark"} className={"mt-4"} title={title} description={description} />
            </AccountSelect>
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comOrgNavigation__LocalizedNavigationDelimiter__urProfile" }, intl)}
            />
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={OrgRouteNames.organization}
                params={{ oid: organizationStore.oid }}
            >
                <img src={iconGroup} alt="" className="mr-3" />
                <LocalizedText id={"comOrgNavigation__RouterLink__organization"} />
            </RouterLink>
            <RouterLink
                activeClassName={navigationStyle.navigationActive}
                routeName={OrgRouteNames.organizationDocuments}
                params={{ oid: organizationStore.oid }}
            >
                <img src={iconDoc} alt="" className="mr-3" />
                <LocalizedText id={"comOrgNavigation__RouterLink__organizationDocuments"} />
            </RouterLink>
            {!!organizationStore.organization?.canViewSupportChat && (
                <RouterLink
                    activeClassName={navigationStyle.navigationActive}
                    routeName={OrgRouteNames.organizationSupport}
                    params={{ oid: organizationStore.oid }}
                >
                    <img src={iconPerson} alt="" className="mr-3" />
                    <LocalizedText id={"comOrgNavigation__RouterLink__organizationSupport"} />
                </RouterLink>
            )}
            {!!organizationStore.organization?.isAccountingEnabled && (
                <React.Fragment>
                    <LocalizedNavigationDelimiter
                        content={useLocalizedText({ id: "comOrgNavigation__LocalizedNavigationDelimiter__buh" }, intl)}
                    />
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.contractList}
                        params={{ oid: organizationStore.oid }}
                    >
                        <img src={iconList} alt="" className="mr-3" />
                        <LocalizedText id={"comOrgNavigation__RouterLink__contractList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.accountList}
                        params={{ oid: organizationStore.oid }}
                    >
                        <img src={iconPayment} alt="" className="mr-3" />
                        <LocalizedText id={"comOrgNavigation__RouterLink__accountList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.serviceProvisionList}
                        params={{ oid: organizationStore.oid }}
                    >
                        <img src={iconShopTwo} alt="" className="mr-3" />
                        <LocalizedText id={"comOrgNavigation__RouterLink__serviceProvisionList"} />
                    </RouterLink>
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={AccountingRouteNames.serviceList}
                        params={{ oid: organizationStore.oid }}
                    >
                        <img src={iconShop} alt="" className="mr-3" />
                        <LocalizedText id={"comOrgNavigation__RouterLink__serviceList"} />
                    </RouterLink>
                </React.Fragment>
            )}
            {!!organizationStore.connectableServices.length && (
                <LocalizedNavigationDelimiter
                    content={useLocalizedText({ id: "comOrgNavigation__LocalizedNavigationDelimiter__services" }, intl)}
                />
            )}
            {organizationStore.connectableServices.map((service) => (
                <RouterLink
                    key={service.id}
                    activeClassName={navigationStyle.navigationActive}
                    routeName={OrgRouteNames.organizationApp}
                    params={{ oid: organizationStore.oid, appId: service.id.toString() }}
                >
                    <img src={iconDoc} alt="" className="mr-3" />
                    {service.name}
                </RouterLink>
            ))}
            {organizationStore.hasBookKeeperConnections && (
                <React.Fragment>
                    <LocalizedNavigationDelimiter
                        content={useLocalizedText(
                            { id: "comOrgNavigation__LocalizedNavigationDelimiter__project" },
                            intl
                        )}
                    />
                    <RouterLink
                        activeClassName={navigationStyle.navigationActive}
                        routeName={OrgRouteNames.projectList}
                        params={{ oid: organizationStore.oid }}
                    >
                        <img src={iconFolder} alt="" className="mr-3" />
                        <LocalizedText id={"comOrgNavigation__RouterLink__projectList"} />
                    </RouterLink>
                </React.Fragment>
            )}
            <LocalizedNavigationDelimiter
                content={useLocalizedText({ id: "comOrgNavigation__LocalizedNavigationDelimiter__organization" }, intl)}
            />
            <a href="#" onClick={onLogout}>
                <img src={iconLogOut} alt="" className="mr-3" />
                <LocalizedText id={"comOrgNavigation__RouterLink__logout"} />
            </a>
        </NavigationSidebar>
    );
};

export const OrgShell: FC = () => {
    const {
        rootStore: { routerStore, organizationLoginStore, organizationStore, userProfileStore },
    } = useRootStore();
    const intl = useIntl();
    return useObserver(() => (
        <div className={style.fullHeight}>
            <div className={style.fullHeight + " container ml-0 " + style.fullWidth}>
                <div className={style.flexList + " row " + style.fullHeight}>
                    <div className={"col-lg-2 " + style.orgBackground}>
                        <div className={"sticky-top mt-4"}>
                            <OrgNavigation
                                organizationStore={organizationStore}
                                accountSelectStore={userProfileStore}
                                title={
                                    organizationStore.organization?.title ??
                                    useLocalizedText({ id: "loadLoading" }, intl)
                                }
                                description={useLocalizedText({ id: "comOrgShell__description" }, intl)}
                                onLogout={() => organizationLoginStore.logOut()}
                            />
                        </div>
                    </div>
                    <div className={"col-lg-10 p-0 m-0"}>
                        <SuspensePlaceholder>
                            <RouterView routerStore={routerStore} viewMap={{ ...OrgViewMap, ...AccountingViewMap }} />
                        </SuspensePlaceholder>
                    </div>
                </div>
            </div>
        </div>
    ));
};
